export const questions = [
    {
        questionText: 'Как команда собирает требования?',
        type: 'single',
        answerOptions: [
            { 
                answerText: 'Применяем водопадную модель. Аналитики анализируют весь проект наперед ', 
                score: -1
            },
            { 
                answerText: 'Аналитики со стейкхолдерами — клиентами и бизнесом — прорабатывают требования на 2–3 спринта, формализуют их в ТЗ и отдают разработчикам', 
                score: 2
            },
            { 
                answerText: 'Аналитики запираются в комнате без окон, прорабатывают требования на 2–3 спринта и кидают в разработчиков ТЗ', 
                score: 0 
            },
            { 
                answerText: 'Аналитики и разработчики сообща работают в спринте над реализацией. Постановки не высечены в камне и изменяются после обсуждения с инженерами', 
                score: 2 
            },
        ],
        description: `
        <p>В первую очередь проверьте компанию на водопадность.</p>
        <p>
            Даже самая распоследняя водопадная компания вам ни за что не скажет, что работает по водопаду. 
            Сейчас весь мир agile. Но есть пропасть между <i>be agile</i> и <i>do agile</i>. И только в <i>be agile</i> командах 
            у вас может появиться возможность комфортно писать качественный софт и влиять на решения, а не просто реализовывать ТЗ.
        </p>    
        <p>
            Для <i>be agile</i> команде нужна ранняя обратная связь от пользователей. 
            Только это позволяет решать реальные проблемы и развивать продукт. 
            Для этого нужно, чтобы аналитики поговорили с живыми людьми, 
            а ещё лучше провели А/Б тестирование прототипов пользовательского интерфейса.
        </p>`
            
    },
    {
        questionText: 'Как прорабатываются задачи перед уходом в разработку?',
        type: 'single',
        answerOptions: [
            { answerText: 'Задачи обсуждаются с Dev, QA или BA', score: 2 },
            { answerText: 'Зачем их прорабатывать, взял и сделал', score: 0 }
        ],
        description: `
            <p>
                Ответ на этот вопрос помогает понять, используется ли на проекте true agile процесс и shift-left quality approach.
            </p>
            <p>
                В <i>do agile</i> командах вы никогда не встретите BE, Dev и QA, работающих над одной задачей и даже в одном спринте. 
                Но это одно из  основополагающих отличий. 
            </p>
            <p>
                BA не должны писать постановки про запас, а после кидать их в разработчиков с дежурным отговоркой «читай, там всё написано».
                QA должны подключаться уже на этапе проработки требований и обдумывать задачу с точки зрения edge-кейсов.
            </p>
            <p>
                В итоге команда не пишет код, пока не рассмотрит задачу с точек зрения бизнеса, возможностей разработки, качества и безопасности. И каждый из участников будет иметь равный голос и право на разумное «нет». 
            </p>
            <p>
                Если вы нашли команду, в которой каждая задача обсуждается тремя амиго — будьте уверены, с agile там всё в порядке.
            </p>
        `
    },
    {
        questionText: 'Как фиксируются принятые архитектурные решения',
        type: 'single',
        answerOptions: [
            { answerText: 'В Architectural Decision Record фиксируются все «Почему» ', score: 2 },
            { answerText: 'В таск-трекере заводят задачи, в которых расписаны все «Как». Например, прикрутить kafka', score: 0 },
            { answerText: 'Решения не фиксируются', score: 0 },
        ],
        description: `
        <p>
            Плохо дело, когда команда не может вспомнить, почему выбрали MongoDB или куда-то натыкали микросервисов. 
            ак «исторически сложилось» или тимлид принёс с последней high-load конференции, но суть в том, что никто не вспомнит, 
            на основании чего принимались решения.
        </p>
        <p>
            Без понимания причин команда обречена ходить по кругу, как в кино про день сурка. 
            Монолит → распределенная структура  → монолит, и так, пока все не выгорят.
        </p>
        <p>
            Зрелые команды ведут Architectural Decision Records, в которых фиксируют значимые архитектурные решения, 
            их причины, альтернативы, плюсы и минусы.
        </p>
        <p>
            Таски в Jira для этого плохо подходят. В них редко записывают результат исследования и причины изменений. 
            А сами таски часто теряются. Попробуйте найти задачу, закрытую два года назад.
        </p>`
    },
    {
        questionText: 'Можно ли начать полноценно разрабатывать в первый же рабочий день?',
        type: 'single',
        answerOptions: [
            { answerText: 'Да, команда выдаст доступы, можно тут же развернуть и запустить проект ', score: 2 },
            { answerText: 'Нет, весь первый день уйдёт на бумажки и получение доступов', score: 0 },
        ],
        description: `
        <p>
            Вопрос помогает оценить степень бюрократии на проекте и производственную культуру. 
        </p>
        <p>
            Если заведение всех нужных учёток занимает недели, представьте, что там у них с другими процессами.
        </p>
        <p>
            Скорость и простота развертывания тоже много вам скажут.
        </p>
        <p>
            Например, тимлид хвастается, что у них такой большой и важный проект, что его нельзя просто так взять и развернуть. 
            У новичка на это уходит три дня, а у гениального новичка — всего два.
        </p>
        <p>
            Но три дня на развертывание говорят только о том, что тимлид не понимает своей основной задачи — упрощать работу разработчиков.
        </p>
        <p>
            Любой проект должен собираться и запускаться по одной команде. 
            Если вместо кнопки длинный readme по запуску, поздравляю, вы нашли зону роста. Конвертируйте readme в bash-скрипт.
        </p>`
    },
    {
        questionText: 'Получится ли комфортно работать с личного ноута на необитаемом острове, где есть только 3G',
        type: 'single',
        answerOptions: [
            { answerText: 'Да, но, возможно, первый билд пройдёт немного болезненно', score: 2 },
            { answerText: 'В принципе да, но будут проблемы', score: 1 },
            { answerText: 'Нет, какой ноут, какой остров?', score: 0 },
        ],
        description: `
        <p>
            Даже если вы не собираетесь работать под пальмой в Таиланде, хорошо бы понимать, 
            сколько телодвижений необходимо для начала работы. А именно:
            <ul>
                <li>сколько всякого рода VPNов надо подключить, чтобы добраться до репозитория или продакшена</li>
                <li>доступны ли трекеры задач, хранилища артефактов и прочая инфраструктура извне</li>
                <li>сможете ли вы достучаться до DEV-кластера не из сети компании. Или вы вообще не сможете собрать билд, потому что кто-то включил обращения к тестовому серверу в unit-тесты.</li>
            </ul>
        </p>
        <p>
            Из всего этого безобразия складывается удобство работы.
        </p>`
    },
    {
        questionText: 'Можно ли локально запустить кластер, состоящий из большинства разрабатываемых приложений и прогнать на нём e2e-тесты',
        type: 'single',
        answerOptions: [
            { answerText: 'Он запускается одной кнопкой (одним bash-скриптом) ', score: 2 },
            { answerText: 'Можно, но нужно стартовать и настраивать каждое приложение', score: 1 },
            { answerText: 'Звучит сложно, ни разу не пробовали', score: 0 },
        ],
        description: `
        <p>
            Правило хорошего тона: весь кластер можно запускать локально и натравливать e2e-тесты. 
        </p>
        <p>
            Это значительно упрощает жизнь при фиксе багов, настройке CI и тестировании фич.
        </p>
        <p>
            Для первоначальной настройки CI потребуются ровно те же действия, что и для локального запуска. Удаленный кластер не придётся настраивать, если работает локально — заработает и на CI-сервере.
        </p>
        <p>
        Представьте, что новая фича пронизывает несколько микросервисов. Вы разрабатываете на одном, пушите код в мастер, чтобы он появился на стенде, переключаетесь на другой микросервис, конектитесь к первому на только что разработанный endpoint и находите в нём ошибку. 
        Переключаетесь на первый, пушите в репозиторий, проходите код ревью, мержите в мастер. С локальным запуском можно сразу развернуть кластер и тестировать такую фичу end-to-end.
        </p>`
    },
    {
        questionText: 'Как быстро коммит по рядовой задаче окажется на Проде?',
        type: 'single',
        answerOptions: [
            { answerText: 'К концу дня', score: 2 },
            { answerText: 'К концу итерации ', score: 1 },
            { answerText: 'Это решит бизнес, гадая на курице с отрубленной головой', score: 0 },
        ],
        description: `
        <p>
            Этот вопрос перекликается с Lean принципом. Если мы говорим о разработке качественного и надежного ПО, 
            то чем быстрее вы выкатываете обновление на production и чем чаще вы это можете делать, темраньше вы получите 
            feedback от пользователей, тем быстрее проверите идеи, да и просто сможете быстрее пофиксить баг.
        </p>
        <p>
            В итоге, самый надежный софт - не тот, где для обновления продакшен стенда требуется собрать 5 подписей 
            и пройти 7 кургов ада приемочных тестов, а тот который может быть задеплоин быстро и автоматически. 
            Конечно же с прохождением автоматических тестов.
        </p>`
    },
    {
        questionText: 'Что можно пропустить, когда дедлайн уже близок',
        type: 'single',
        answerOptions: [
            { answerText: 'Написание Unit-тестов', score: 0 },
            { answerText: 'Написание e2e-тестов ', score: 0 },
            { answerText: 'Отложить задачи из технического беклога', score: 0 },
            { answerText: 'Ничего из вышеперечисленного', score: 2 },
        ],
        description: `
        <p>
            Дедлайн показывает, во что человек верит по-настоящему. Мы используем различные практики, чтобы снижать стоимость, 
            расход нервов и время разработки. Отказ от практик говорит о дефиците веры в их эффективность.
        </p>`
    },
    {
        questionText: 'Как прорабатывается графический интерфейс приложения?',
        type: 'single',
        answerOptions: [
            { answerText: 'Над ним работает UX-дизайнер, имплементируют разработчики', score: 2 },
            { answerText: 'Фронтенд-разработчики раскидывают кнопки и инпуты, получается ок ', score: 0 },
        ],
        description: `
        <p>
            Интерфейс Jenkins — отличный пример того, что получается у разработчиков без участия UX-дизайнера. 
            Гоните прочь мысль, что верстальщик раскидает кнопочки, а пользователь уж как-нибудь разберётся.
        </p>
        <p>
            Грамотный UX дизайнер сделает интерфейс человекочитаемым. 
            Проработает архитектуру информации, чтобы пользователь легко находил нужное. Проследит за единообразием стиля, ч
            тобы дружелюбное приветствие на главной совпадало по тону с сообщениями об ошибках. 
            Проверит дизайн на пользователях и внесет доработки по результатам этих тестов. Верстальщик так не сможет.
        </p>
        <p>
            Если пользователю не комфортно работать с приложением, неважно, сколько килореквестов в секунду оно обрабатывает.
        </p>`
    },
    {
        questionText: 'Как точнее всего описывается архитектура проекта?',
        type: 'single',
        answerOptions: [
            { answerText: 'Через инструменты. Например, «REST сервисы на Spring Boot, на беке у нас hibernate c PostgreSQL»', score: 0 },
            { answerText: 'Через контексты бизнеса и модули. Например, «сервис с распределенной архитектурой и асинхронной обработкой сообщений, поскольку разные пользователи и сценарии чувствительны к разным архитектурным характеристикам» ', score: 2 },
        ],
        description: `
        <p>
            Архитектура — расплывчатое понятие и описывают её как угодно, от инфраструктуры до организации бизнес-процессов. 
            Важно понять, что преобладает в голове у её авторов: выбранные инструменты или причины выбора этих инструментов. 
            Хорошо, когда причины преобладают.
        </p>
        <p>
            Например, можно ли понять, почему выбрали новомодную Кафку? 
            Она действительно нужна или она переваривает по 5 сообщений в день просто потому, что техлид принес её с последней конференции.
        </p>`
    },
    {
        questionText: 'Работа с техническим долгом',
        type: 'single',
        answerOptions: [
            { answerText: 'Бэклог приоритезирован, пополняется ежедневно. Технический долг считается first class citizens ', score: 2 },
            { answerText: 'Задачами из технического бэклога занимаются после задач бизнеса, когда в спринте остаются пустые места', score: 1 },
            { answerText: 'Технический бэклог не ведется', score: 0 },
        ],
        description: `
        <p>
            Здоровые команды профессионалов часто не делят бэклог на технический долг и фичи, а осознают их важность для продукта в конкретной ситуации. 
            В такой команде PM будет настаивать на закрытии техдолга, а разработчики — на внедрении фич, особенно если они сами пользуются своим продуктом.
        </p>`
    },
    {
        questionText: 'Насколько здорова пирамида тестирования?',
        type: 'single',
        answerOptions: [
            { answerText: 'Образцовая. Обширная база unit-тестов, применение TDD, интеграционных тестов, e2e и немного behavior-тестов', score: 2 },
            { answerText: 'Песочные часы. Много Unit, много behavior-тестов, которые написаны QA, а в середине почти пусто', score: 1 },
            { answerText: 'Сложно сказать', score: 0 },
            { answerText: 'Стартапу тесты ни к чему, главное — рост и захват рынка!', score: 0 },
        ],
        description: `
        <p>
            Здоровая пирамида тестирования снижает потери нервных клеток при деплое и позволяет перейти к continuous deployment при необходимости.
        </p>
        <p>
            Отсутствие тестов — явный признак ручного «приемочного тестирования» и ежемесячной релизной лихорадки, 
            когда кто-то задорно мержит в уже протестированное, и прочее дно кровавого энтерпрайза.
        </p>`
    },
    {
        questionText: 'Чем занимаются тестировщики?',
        type: 'single',
        answerOptions: [
            { answerText: 'Анализируют задачи перед принятием в работу, находят в задаче edge cases ещё до начала разработки, могут разрабатывать наравне с программистами', score: 2 },
            { answerText: 'Ручным тестированием перед релизом', score: 0 },
            { answerText: 'Автоматизированным тестированием, покрывают функции приложения e2e-тестами', score: 0 },
            { answerText: 'В команде нет тестировщиков', score: 1 },
        ],
        description: `
        <p>
            Отлично, если QA могут разрабатывать совместно с программистом. 
            Совсем отлично, когда QA — не должность, а роль, которую попеременно выполняют члены команды или берут на себя особо ответственные. 
            В таких командах может не быть формального QA.
        </p>
        <p>
            Антипаттерн — тестировщики занимаются «приемочным тестированием»: присоединяются к задаче в конце и начинают её возвращать на доработку. 
            В худшем случае задача переделывается целиком.
        </p>
        <p>
            В промежуточном варианте тестировщики могут похвастаться, что написали 100 автотестов и покрыли 25 % функций, 
            осталось написать ещё 300. Это говорит о двух вариантах:
            <ul>
                <li>Разработчики пишут unit-тесты и вместо пирамиды тестирования у них песочные часы.</li>
                <li>Разработчики тестов не пишут и у них перевернутая пирамида.</li>
            </ul>
        </p>
        <p>
            В любом случае есть вероятность, что тестировщики в своих BDD-тестах ощутимо далеки от тестов, написанных разработчиками. 
            Это значит, что тесты QA будут хрупкими и тяжелыми. Изменится вёрстка, добрая половина тестов отвалится и их буду чинить весь спринт.
        </p>
        `
    },
    {
        questionText: 'При каких условиях свалится локальный билд?',
        type: 'multi',
        answerOptions: [
            { answerText: 'Зафейлился один из тестов', score: 0.5 },
            { answerText: 'Неиспользуемый импорт', score: 0.5 },
            { answerText: 'Код не соответствует стандарту кодирования', score: 0.5 },
            { answerText: 'Есть потенциальный баг', score: 0.5 },
        ],
        description: `
        <p>
            Локальный билд обязан валиться при сломанном тесте, жалобах линтеров на потенциальные ошибки в коде или на стиль кодирования.
        </p>
        <p>
            Мы говорим именно о локальном билде, а не о CI-pipeline из-за shift-left подхода. Жалобу локального сборщика проще заметить и принять меры. 
            Если же что-то отвалится на CI, вы заметите это лет через десять, когда весь контекст будет утерян.
        </p>`
    },
    {
        questionText: 'Используется ли TDD?',
        type: 'single',
        answerOptions: [
            { answerText: 'Да, продакшн-код пишется после тестов для него. На написание теста уходит 2–3 минуты', score: 2 },
            { answerText: 'Покрытие тестами идёт отдельной задачей ', score: 0 },
            { answerText: 'Нет', score: 0 },
        ],
        description: `
        <p>
            Наличие одних unit-тестов не гарантирует безошибочную работу приложения. 
            Важен не инструмент, а процесс. Если при сборке приложения отваливается один из тестов — приложение однозначно сломано. 
            Но с зелёной полосой нет никакой гарантии, что ошибок нет. С TDD всё однозначно: красная полоса — фиксим, зеленая — выкатываем на прод. 
        </p>`
    },
    {
        questionText: 'Сколько часов команда переработала за последний месяц?',
        type: 'single',
        answerOptions: [
            { answerText: 'Никаких переработок', score: 2 },
            { answerText: 'Пришлось немного поднапрячься к концу проекта', score: 0 },
            { answerText: 'Переработки в норме и поощряются', score: 0 },
        ],
        description: `
        <p>
            IT-сообщество регулярно развлекается научным обоснованием неизбежности и необходимости переработок. Но переработки имеют три простые причины:
        </p>
        <p>
            <b>Проект изначально не помещался в бюджет.</b> 
            Условно, по оценке нужно полтора года, но у заказчика денег только на год. 
            Часто менеджеры решают влезть в деньги за счёт ускорения разработки и неизбежного кранча перед дедлайном. 
        </p>
        <p>
            <b>Проект неправильно оценили и он сильно затягивается.</b> 
            Разработчиков это волновать не должно, но закрывать брешь придётся им.
        </p>
        <p>
            <b>В компании такая традиция.</b>
            Авторам встречались команды, настолько привыкшие перерабатывать, что сидят по вечерам и выходным без особой необходимости. 
            Сами сидят и осуждают новичков, которые пытаются уйти после шести вечера.
        </p>
        <p>
            Если проекту не хватает времени, денег или производственной культуры, 
            то недостающее всегда покрывается ресурсом разработчиков: их личным временем, здоровьем и нервами.
        </p>
        `
    },
    {
        questionText: 'Как команда узнает о проблемах на проде?',
        type: 'single',
        answerOptions: [
            { answerText: 'От менеджера, который прочитал о падении сервиса в твиттере конкурентов', score: 0 },
            { answerText: 'Из мониторинга, задолго до того, как основная часть пользователей что-то заметит ', score: 2 },
        ],
        description: `
        <p>
            Без нормального логирования и мониторинга разработчики обречены копаться в логах прода. 
            При этом мониторинг должен отображать и состояние систем, с которыми интегрирован сервис.
             Иначе можно провести час по пояс в логах и с наскипидаренным менеджером за спиной, 
             чтобы понять, что лежит не сам сервис, а биллинг партнёров.
        </p>`
    },
];